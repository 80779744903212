export enum TerminalTextStyle {
  Green,
  Sub,
  White,
  Red,
  Blue,
  Invisible,
  Default,
  Underline,
  Hoverable,
}
