// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/icomoon.eot?55l5wm");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/icomoon.ttf?55l5wm");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/icomoon.woff?55l5wm");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/icomoon.svg?55l5wm");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#icomoon" });
// Module
exports.push([module.id, "@font-face {\n  font-family: 'icomoon';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('embedded-opentype'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('svg');\n  font-weight: normal;\n  font-style: normal;\n  font-display: block;\n}\n\n[class^='icon-'],\n[class*=' icon-'] {\n  /* use !important to prevent issues with browser extensions that change fonts */\n  font-family: 'icomoon' !important;\n  speak: never;\n  font-style: normal;\n  font-weight: normal;\n  font-variant: normal;\n  text-transform: none;\n  line-height: 1;\n\n  /* Better Font Rendering =========== */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.icon-github:before {\n  content: '\\e903';\n}\n.icon-discord:before {\n  content: '\\e900';\n}\n.icon-twitter:before {\n  content: '\\e901';\n}\n.icon-brand:before {\n  content: '\\e901';\n}\n.icon-tweet:before {\n  content: '\\e901';\n}\n.icon-social:before {\n  content: '\\e901';\n}\n.icon-mail:before {\n  content: '\\e902';\n}\n.icon-contact:before {\n  content: '\\e902';\n}\n.icon-support:before {\n  content: '\\e902';\n}\n.icon-newsletter:before {\n  content: '\\e902';\n}\n.icon-letter:before {\n  content: '\\e902';\n}\n.icon-email:before {\n  content: '\\e902';\n}\n.icon-envelop:before {\n  content: '\\e902';\n}\n.icon-social1:before {\n  content: '\\e902';\n}\n", ""]);
// Exports
module.exports = exports;
